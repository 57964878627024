import axios from "axios"
import {BASE_URL} from "../config"

const getData = (start, end, instrumentName, interval, useHistorical) => {
  return axios.get(BASE_URL + '/tick', {params: {start, end, interval, instrumentName, useHistorical: 1}})
}
const chartService = {
  getData
}
export default chartService;
