import axios from "axios"

export const createTrigger = (trigger) => {
  console.log('trigger: ', trigger)
  return axios.post('/triggers', trigger)
}

export const abortTrigger = (trade_id, trigger_id) => {
  return axios.put('/triggers/abort', {trade_id, trigger_id})
}
