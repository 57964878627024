import React, {useCallback, useContext, useEffect, useState} from 'react'
import {MainContext} from "../ChartDemoHook"
import {getNiftyOptions} from "../../../utils"
import {Button, ButtonGroup, Tag} from "@blueprintjs/core"
import moment from 'moment-timezone'
import {getLTP} from "../../../service/kc"

export const findToken = (name, strike, bors) => {
  const instruments = window.MY_INSTRUMENTS
  const instrument_type = bors ? 'CE' : 'PE'
  const nameInstruments = instruments.filter(i => i.name === name && i.strike === strike && i.segment === 'NFO-OPT' && i.instrument_type === instrument_type)
  const sortedInstruments = nameInstruments.sort((a, b) => {
    return moment(a.expiry).diff(moment(b.expiry))
  })
  const nearest = sortedInstruments[0]
  return nearest
}
const OptionChain = ({mainDispatch, options}) => {
  const expiry = options.ITM.expiry
  const name = options.ITM.name
  const instrument_type = options.ITM.instrument_type
  const expiryDate = moment(expiry)
  return (
    <div className={'flex m-2'}>
      <span className={'mr-2'}>
        {name} {' '} {instrument_type} {expiryDate.format('MMM DD')} {' '}
      </span>
      <ButtonGroup>
        <OptionButton option={options.DITM} tagLabel={'DITM'} mainDispatch={mainDispatch}/>
        <OptionButton option={options.ITM} tagLabel={'ITM'} mainDispatch={mainDispatch}/>
        <OptionButton option={options.ATM} tagLabel={'ATM'} mainDispatch={mainDispatch}/>
        <OptionButton option={options.OTM} tagLabel={'OTM'} mainDispatch={mainDispatch}/>
        <OptionButton option={options.DOTM} tagLabel={'DOTM'} mainDispatch={mainDispatch}/>
      </ButtonGroup>
    </div>
  )
}
const OptionButton = ({mainDispatch, tagLabel, option}) => {
  const { strike, instrument_token} = option
  return (
    <Button onClick={() => mainDispatch({type: "SET_CHART_TOKEN", token: instrument_token})}>
      <Tag minimal rounded>{tagLabel}</Tag>
      {` ${strike}`}
    </Button>
  )
}

const ChartOptionsControls = (props) => {
  const {mainDispatch} = useContext(MainContext)
  const [niftyCall, setNiftyCall] = useState([])
  const [niftyPut, setNiftyPut] = useState([])
  const [bankNiftyCall, setBankNiftyCall] = useState([])
  const [bankNiftyPut, setBankNiftyPut] = useState([])
  const refreshOptions = useCallback(async () => {
    if(window.MY_INSTRUMENTS) {
      const ltpInfo = await getLTP([256265, 260105])
      const niftyLTP = ltpInfo.data[256265]["last_price"]
      const bankNiftyLTP = ltpInfo.data[260105]["last_price"]
      const niftyCallOptions = getNiftyOptions(niftyLTP, true)
      const niftyPutOptions = getNiftyOptions(niftyLTP, false)
      const bankNiftyCallOptions = getNiftyOptions(bankNiftyLTP, true)
      const bankNiftyPutOptions = getNiftyOptions(bankNiftyLTP, false)
      const keys = Object.keys(niftyCallOptions)
      keys.forEach((k) => {
        niftyCallOptions[k] = findToken('NIFTY', niftyCallOptions[k], true)
        niftyPutOptions[k] = findToken('NIFTY', niftyPutOptions[k], false)
        bankNiftyCallOptions[k] = findToken('BANKNIFTY', bankNiftyCallOptions[k], true)
        bankNiftyPutOptions[k] = findToken('BANKNIFTY', bankNiftyPutOptions[k], false)
      })
      setNiftyCall(niftyCallOptions)
      setNiftyPut(niftyPutOptions)
      setBankNiftyCall(bankNiftyCallOptions)
      setBankNiftyPut(bankNiftyPutOptions)
    }
  }, [])
  useEffect(() => {
    refreshOptions()
  }, [refreshOptions])

    return (
	  <div className={'p-5'}>
      <Button onClick={refreshOptions}>
        REFRESH OPTIONS
      </Button>
      {
        niftyCall?.ITM?.instrument_token &&
          niftyPut?.ITM?.instrument_token &&
          bankNiftyCall?.ITM?.instrument_token &&
          bankNiftyPut?.ITM?.instrument_token ? (
          <>
            <OptionChain options={niftyCall} mainDispatch={mainDispatch}/>
            <OptionChain options={niftyPut} mainDispatch={mainDispatch}/>
            <OptionChain options={bankNiftyCall} mainDispatch={mainDispatch}/>
            <OptionChain options={bankNiftyPut} mainDispatch={mainDispatch}/>
          </>
        ) : ''
      }

	  </div>
    )
}
export default ChartOptionsControls
