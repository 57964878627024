import moment from "moment"
import Header from "./components/Header"
import React, {useCallback, useState} from "react"
import OrdersTable from "./components/OrdersTable"
import ControlsSection from "./components/ControlsSection"
import PositionsTable from "./components/PositionsTable"
import {computeM2M} from "../../utils"
import {Alert, Button, Switch} from "@blueprintjs/core"
import TriggersTable from "./components/TriggersTable"
import TradePlan from "./components/TradePlan"
import ReactJson from 'react-json-view'
import {postAlgoAction} from "../../service/algoAction"

const M2MDisplay = ({m2m}) => {
  if(!m2m) return ''
  const keys = Object.keys(m2m)
  return (
    <div>
      {
        keys.map((key) => (
          <div key={key}>
            {key} {'==>'} {m2m[key].toFixed(2)}
          </div>
        ))
      }

    </div>
  )
}

const ChartTradeWindow = ({
                            trade_id,
                            inFocus,
                            onClearOrders,
                            onRefresh,
                            trade,
                            onControl,
                            duplicateTrade,
                            addTradeLog,
                            onFocusNextTrade
                          }) => {
  const [m2m, setM2M] = useState({})
  const [showM2M, setShowM2M] = useState(false)
  const [showOrders, setShowOrders] = useState(false)
  const [showControls, setShowControls] = useState(false)
  const [showTriggers, setShowTriggers] = useState(true)
  const [showPositions, setShowPositions] = useState(true)
  const [isIntraday, setIsIntraday] = useState(false)
  const calculateAndShowM2MAlert = useCallback(async () => {
    await calculateM2M()
    await showM2MAlert()
  }, [trade.allOrders])
  const calculateM2M = useCallback(async () => {
    const m2m = await computeM2M(trade.allOrders);
    const now = moment()
    setM2M({lastUpdated: now, m2m})
  }, [trade.allOrders])
  const showM2MAlert = useCallback(async () => {
    setShowM2M(true)
  }, [showM2M])
  const onQuickTrigger = useCallback(async (actionType, actionInfo) => {
    await postAlgoAction({
      "algo_trade_id": trade_id,
      "algo_action_type": actionType,
      "algo_action_info": actionInfo
    })
  }, [])
  return (
    <div className={'py-2'}>
      <Header
        trade_id={trade_id}
        strategy_id={trade.strategy_id}
        onRefresh={onRefresh}
        tradeActive={trade.active}
        onClearOrders={onClearOrders}
        inFocus={inFocus}
        duplicateTrade={() => duplicateTrade(trade_id)}
        calculateM2M={calculateAndShowM2MAlert}
        onSignOff={() => onControl({type: 'SIGN_OFF_TRADE', trade_id, doSignOff: true})}
        showControls={showControls}
        setShowControls={setShowControls}
        showTriggers={showTriggers}
        setShowTriggers={setShowTriggers}
        showPositions={showPositions}
        setShowPositions={setShowPositions}
        showOrders={showOrders}
        setShowOrders={setShowOrders}
        onFocusNextTrade={onFocusNextTrade}
        onSquareOff={() => onControl({type: 'SQUARE_OFF_TRADE', trade_id})}
      />
      <Alert isOpen={showM2M} onClose={() => setShowM2M(false)}>
        <M2MDisplay m2m={m2m.m2m}/>
      </Alert>
      <div className={'window-content'}>
        <div className={'px-4'}>
          <div className={'trade-detail'}>
            <TradePlan trade={trade} addTradeLog={addTradeLog}/>
            <div className={'temp-m2m-display my-2'}>
              <M2MDisplay m2m={m2m.m2m}/>
              <Button onClick={calculateM2M} className={'my-1'}>Refresh M2M</Button>
            </div>
            <div className={'paper-or-live my-2'}>
              <Switch checked={isIntraday} inline label="Paper"
                      onChange={(e) => setIsIntraday(!isIntraday)}/>
            </div>

            <div className={'mt-4'}>

              {
                trade?.state ? (
                  <ReactJson
                    src={trade.state}
                    collapsed={1}
                    onEdit={(edit) => {
                      onControl({
                        type: 'CREATE_ALGO_ACTION',
                        algoAction: {
                          "algo_trade_id": trade_id,
                          "algo_action_type": "EDIT_DB_STATE",
                          "algo_action_info": {
                            "name": edit.name,
                            "newValue": edit.new_value,
                            "namespace": edit.namespace
                          }
                        }
                      })
                    }}
                    //codeshool tomorrow ocean flat
                    theme={'flat'}

                  />
                ) : ''
              }
            </div>
          </div>
          <div>
            {
              showOrders ? (
                <OrdersTable orders={trade.allOrders}/>
              ) : ''
            }
            {
              showPositions ? (
                <PositionsTable positions={trade.positions} onControl={onControl}/>
              ) : ''
            }
            {
              showTriggers ? (
                <TriggersTable triggers={trade?.triggers} trade={trade} onControl={onControl}/>
              ) : ''
            }
          </div>
          {
            showControls ? (
              <ControlsSection onControl={onControl} onRefresh={onRefresh}
                               duplicateTrade={() => duplicateTrade(trade_id)}
                               isIntraday={isIntraday}
                               setIsIntraday={setIsIntraday}
                               onSignOff={() => onControl({type: 'SIGN_OFF_TRADE', trade_id, doSignOff: true})}
                               calculateM2M={calculateAndShowM2MAlert} onQuickTrigger={onQuickTrigger}/>
            ) : ''
          }
        </div>
      </div>
    </div>
  )
}

export default ChartTradeWindow
