import {chartOrderStateHandler, getInitialChartOrder} from "./chartOrderUtils"
import {combineReducers} from "../../utils"

const NIFTY_TOKEN = "256265"
export const initialState = {
  chartSettings: {
    interval: "15",
    liveData: false,
    token: NIFTY_TOKEN,
    name: "Nifty 50",
    zoomEnabled: false,
    panEnabled: false
  },
  chartData: null,
  chartTickData: null,
  chartOrder: null,
  pickerPrice: null,
  trades: {
    allTrades: []
  },
  inFocusTradeId: null,
  markOnChart: {
    orders: {},
    triggers: {"manual_v1_135345":{"ga5i213jksk2163d":true}}
  },
  strategies: [],
  triggerTypes: [],
  createTradeForm: {
    modalOpen: false,
    formState: {
      strategy_id: '',
      trade_id: '',
      trade_plan: '',
      risk_profile: "500"
    }
  },
  addTriggerForm: {
    modalOpen: false,
    formState: {
      strategy_id: '',
      trade_id: '',
      trigger_type_id: '',
      triggerParams: {

      }
    }
  }
}

const chartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CHART_NAME":
      return {...state, chartSettings: {...state.chartSettings, name: action.name}}
    case "SET_CHART_INTERVAL":
      return {...state, chartSettings: {...state.chartSettings, interval: action.interval}}
    case "SET_CHART_TOKEN":
      return {...state, chartSettings: {...state.chartSettings, token: action.token}}
    // case "REFRESH_CHART":
    //   return {...state, trigger: {...state.trigger, inEdit: true}}
    case "CHART_DATA_RECEIVED":
      return {...state, chartData: action.chartData}
    case "SWITCH_TO_LIVE":
      console.log('SWITCH_TO_LIVE')
      return {...state, chartSettings: {...state.chartSettings, liveData: true, interval: '15s'}}
    case "SWITCH_TO_HISTORICAL":
      console.log('SWITCH_TO_HISTORICAL')
      return {...state, chartSettings: {...state.chartSettings, liveData: false, interval: '15'}}

    case "CHART_TICK_RECEIVED": {
      return {...state, chartTickData: [...state.chartTickData ,action.tick]}
    }
    case "CHART_INITIAL_TICKS_RECEIVED":
      return {...state, chartTickData: [...action.initialTicks]}
    case "CREATE_CHART_ORDER":
      return {...state, chartOrder: getInitialChartOrder(action.chartOrderType, action.orderQuantity, action.instrumentToken, action.limitPriceOffset)}
    case "CANCEL_CHART_ORDER":
      return {...state, chartOrder: null}
    case "ZOOM_ENABLE":
      return {...state, chartSettings: {...state.chartSettings, zoomEnabled: action.enable}}
    case "PAN_ENABLE":
      return {...state, chartSettings: {...state.chartSettings, panEnabled: action.enable}}
    case "CHART_PRICE_RECEIVED":
      if (!state.chartOrder) return {...state}
      //given current chartOrderState and a price, I want the next chartOrderState
      const newChartOrder = chartOrderStateHandler(state.chartOrder, action.price)
      return {...state, chartOrder: newChartOrder}
    default:
      return state
  }
}
const tradesReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_CREATE_TRADE_MODAL":
      return {...state, createTradeForm: {...state.createTradeForm, modalOpen: true}}
    case "SHOW_ADD_TRIGGER_MODAL":
      return {...state, addTriggerForm: {...state.addTriggerForm, modalOpen: true}}
    case "HIDE_CREATE_TRADE_MODAL":
      return {...state, createTradeForm: {...initialState.createTradeForm}}
    case "HIDE_ADD_TRIGGER_MODAL":
      return {...state, addTriggerForm: {...initialState.addTriggerForm}}
    case "DUPLICATE_TRADE":
      return {...state, createTradeForm: {...state.createTradeForm, formState: {...action.formState}, modalOpen: true}}
    case "STRATEGIES_DATA_RECEIVED":
      return {...state, strategies: [...action.strategies]}
    case "TRIGGER_TYPES_DATA_RECEIVED":
      return {...state, triggerTypes: [...action.triggerTypes]}
    case "TRADES_DATA_RECEIVED":
      return {...state, trades: {...state.trades, allTrades: action.tradesData}}
    case "SET_FOCUS_TRADE":
      return {...state, inFocusTradeId: action.trade_id}
    case "MARK_ORDER_ON_CHART": {
      let newMarkOnChartOrders = {...state.markOnChart.orders}
      const log_id = action.log_id
      const trade_id = action.trade_id
      newMarkOnChartOrders[trade_id] = newMarkOnChartOrders[trade_id] || {}
      newMarkOnChartOrders[trade_id][log_id] = true
      return {...state, markOnChart: {...state.markOnChart, orders: {...newMarkOnChartOrders}}}
    }
    case "UNMARK_ORDER_ON_CHART": {
      let newMarkOnChartOrders = {...state.markOnChart.orders}
      const log_id = action.log_id
      const trade_id = action.trade_id
      newMarkOnChartOrders[trade_id] = newMarkOnChartOrders[trade_id] || {}
      newMarkOnChartOrders[trade_id][log_id] = false
      return {...state, markOnChart: {...state.markOnChart, orders: {...newMarkOnChartOrders}}}
    }
    case "MARK_TRIGGER_ON_CHART": {
      let newMarkOnChartTriggers = {...state.markOnChart.triggers}
      const trigger_id = action.trigger_id
      const trade_id = action.trade_id
      newMarkOnChartTriggers[trade_id] = newMarkOnChartTriggers[trade_id] || {}
      newMarkOnChartTriggers[trade_id][trigger_id] = true
      return {...state, markOnChart: {...state.markOnChart, triggers: {...newMarkOnChartTriggers}}}
    }
    case "UNMARK_TRIGGER_ON_CHART": {
      let newMarkOnChartTriggers = {...state.markOnChart.triggers}
      const trigger_id = action.trigger_id
      const trade_id = action.trade_id
      newMarkOnChartTriggers[trade_id] = newMarkOnChartTriggers[trade_id] || {}
      newMarkOnChartTriggers[trade_id][trigger_id] = false
      return {...state, markOnChart: {...state.markOnChart, triggers: {...newMarkOnChartTriggers}}}
    }
    case "TRADE_DETAIL_RECEIVED":
      let modifiedTradeDetail = state.trades.allTrades.map(t => {
        if(t.trade_id === action.trade_id) {
          return {...t, positions: action.positions, triggers: action.triggers, allOrders: action.allOrders}
        } else {
          return {...t}
        }
      })
      return {...state, trades: {...state.trades, allTrades: [...modifiedTradeDetail]}}
    default:
      return state
  }
}
export const combinedReducer = combineReducers([chartReducer, tradesReducer]);
