import axios from "axios"

export const getOrders = (trade_id) => {
  return axios.get(`/orders/?trade_id=${trade_id}`)
}

export const getTradePositions = (trade_id) => {
  return axios.get(`/orders/?mode=positions&trade_id=${trade_id}`)
}

export const clearOrders = (trade_id) => {
  return axios.delete(`/orders/?trade_id=${trade_id}`)
}
