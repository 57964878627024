import io from 'socket.io-client';
import config from "../config"

let socket;
export const initiateSocket = (initialChartToken) => {
  socket = io(config.WS_URL);
  console.log(`Connecting socket...`);
  if (socket ) socket.emit('SET_TOKEN', initialChartToken);
}
export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if(socket) socket.disconnect();
}
export const subscribeToTicks = (cb) => {
  if (!socket) return(true);
  socket.on('TICK_DATA', msg => {
    return cb(null, msg);
  });
}
export const subscribeToInitialTicks = (cb) => {
  if (!socket) return(true);
  socket.on('INITIAL_TICKS', msg => {
    return cb(null, msg);
  });
}
export const setToken = (token) => {
  if (socket ) socket.emit('SET_TOKEN', token);
}
export const sendMessage = (room, message) => {
  if (socket) socket.emit('chat', { message, room });
}