import React, {useState} from 'react'
import moment from 'moment-timezone'
import {Suggest} from "@blueprintjs/select"
import {Button, ButtonGroup, MenuItem} from "@blueprintjs/core"
import {highlightText} from "../../../../utils"

const renderInstrument = (instrument, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  let label = ''
  let text = `${instrument.name}`;
  if(instrument.segment === "NFO-OPT") {
    label = moment(instrument.expiry).format('MMM DD')
    text = instrument.tradingsymbol
  } else if(instrument.segment === "NFO-FUT") {
    label = moment(instrument.expiry).format('MMM DD')
    text = instrument.tradingsymbol
  } else {
    label = instrument.name
    text = instrument.tradingsymbol

  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={label}
      key={instrument.instrument_token}
      onClick={handleClick}
      text={highlightText(text, query)}
    />
  )

}
const filterItems = (query, items) => {
  return items.filter(item => {
    const searchStr = item.tradingsymbol.concat(item.name).toUpperCase()
    return searchStr.includes(query.toUpperCase())
  });
};
const InstrumentSelector = ({instruments, selectedInstrument, mainDispatch}) => {
  const [nse, setNse] = useState(true)
  const [futures, setFutures] = useState(false)
  const [options, setOptions] = useState(false)
  let instrumentsToShow = instruments.filter((i) => {
    if(nse && i.exchange === "NSE") return true
    if(futures && i.segment === "NFO-FUT") return true
    if(options && i.segment === "NFO-OPT") return true
    return false
  })
    return (
	  <div>
      <ButtonGroup>
        <Suggest
          inputValueRenderer={(i) => i.tradingsymbol}
          items={instrumentsToShow}
          itemRenderer={renderInstrument}
          onItemSelect={(i) => mainDispatch({type: "SET_CHART_TOKEN", token: i.instrument_token})}
          itemsEqual={"instrument_token"}
          selectedItem={selectedInstrument}
          itemListPredicate={filterItems}
        />
        <Button icon={`${nse ? 'tick' : null}`} onClick={() => setNse(!nse)}>
          NSE
        </Button>
        <Button icon={`${futures ? 'tick' : null}`} onClick={() => setFutures(!futures)}>
          Futures
        </Button>
        <Button icon={`${options ? 'tick' : null}`} onClick={() => setOptions(!options)}>
          CE/PE
        </Button>
      </ButtonGroup>
	  </div>
    )
}
export default InstrumentSelector
