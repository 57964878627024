
//gives initial state of a chart order depending on type
export const getInitialChartOrder = (chartOrderType, quantity, instrumentToken, limitPriceOffset) => {
  switch (chartOrderType) {
    case 'SELL_MARKET_EMULATED_CO':
    case 'BUY_MARKET_EMULATED_CO': {
      return {
        type: chartOrderType,
        step: 0,
        nSteps: 2,
        stepState: [],
        quantity
      }
    }
    case 'CE_MARKET_TRIGGER_ORDER2': {
      return {
        type: chartOrderType,
        step: 0,
        nSteps: 1,
        stepState: [],
        quantity,
        instrumentToken,
        limitPriceOffset
      }
    }
    case 'PE_MARKET_TRIGGER_ORDER2': {
      return {
        type: chartOrderType,
        step: 0,
        nSteps: 1,
        stepState: [],
        quantity,
        instrumentToken,
        limitPriceOffset
      }
    }
    case 'TRIGGER_SQUAREOFF': {
      return {
        type: chartOrderType,
        step: 0,
        nSteps: 1,
        stepState: [],
        quantity
      }
    }
    default:
      console.log('unknown chartOrderType found')
  }
}
//given current chartOrderState and a price, compute next chartOrderState
// according to different chartOrderTypes
export const chartOrderStateHandler = (chartOrderState, price) => {
  let newChartOrderState = {...chartOrderState}
  const currStep = newChartOrderState.step
  newChartOrderState.stepState[currStep] = price
  newChartOrderState.step = currStep + 1
  return newChartOrderState
}
