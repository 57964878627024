import {Link} from "react-router-dom"
import React from "react"

const Header = () => {
  return (
    <div className={'py-6 px-8'}>
      <div className={'flex space-x-4'}>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/login">Login</Link>
        </div>
        <div>
          <Link to="/chart?interval=60">Chart</Link>
        </div>
      </div>
    </div>
    )
}

export default Header
