import React, {useCallback, useState} from 'react'
import {Button, Classes, Label} from "@blueprintjs/core"
import axios from "axios"

const GenericError = (props) => {
    const [userId, setUserId] = useState('')
    const [openStr, setOpenStr] = useState('')
    const saveUserId = useCallback(() => {
      localStorage.setItem('saved_user_id', userId)
      axios.defaults.headers.common['saved_user_id'] = userId;
      setUserId('')
    },[userId])
  const addToStr = useCallback((e) => {
    setOpenStr(openStr + e)
  },[openStr])
  return (
      <div className={'h-screen'}>
        <div className="bp3-non-ideal-state">
          <div className="bp3-non-ideal-state-visual">
            <span className="bp3-icon bp3-icon-error"></span>
          </div>
          <h4 className="bp3-heading" onClick={saveUserId}>The app is undergoing maintenance</h4>
          <div className={'flex space-x-1'}>
            <div onClick={() => addToStr(1)}>Please</div>
            <div onClick={() => addToStr(2)}>contact</div>
            <div onClick={() => addToStr(3)}>the</div>
            <div onClick={() => addToStr(4)}>site</div>
            <div onClick={() => addToStr(5)}>administrator</div>
            <div onClick={() => addToStr(6)}>for</div>
            <div onClick={() => addToStr(7)}>more</div>
            <div onClick={() => addToStr(8)}>assistance</div>
          </div>
          {
            openStr === '8523467' ? (
              <div className={'mt-5'}>
                Prefer a contact back from us?
                Drop your email here.
                <div className={'flex space-x-3 mt-2'}>
                  <input className={`${Classes.INPUT} w-full`} value={userId} onChange={(e) => setUserId(e.target.value)} placeholder={'Email'}/>
                </div>
              </div>
            ) : ''
          }
        </div>
      </div>
    )
}
export default GenericError
