import axios from "axios"
import {getToken} from "../utils"

const postSignal = (trade_id, action) => {
  return axios.post('/signal', {
    trade_id,
    action
  })
}

export const placeLimitOrder = (trade_id, exchange, symbol, goLong, quantity, price) => {
  let action = {
    tradingsymbol: symbol,
    transaction_type: goLong ? 'BUY' : 'SELL',
    quantity,
    exchange,
    price,
    order_type: "LIMIT",
    paper: false,
    variety: "regular"
  }
  return postSignal(trade_id, action)
}

export const placeMarketOrder = (trade_id, exchange, symbol, goLong, quantity, product) => {
  let action = {
    tradingsymbol: symbol,
    transaction_type: goLong ? 'BUY' : 'SELL',
    quantity,
    exchange,
    order_type: "MARKET",
    paper: false,
    variety: "regular",
    product
  }
  return postSignal(trade_id, action)
}

