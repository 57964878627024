import moment from "moment-timezone"

export const convertTickToCandle = (ticks, timeframe) => {
  // const tickData = testTickData
  const tickData = ticks
  const SUPPORTED_TF = ['1s', '5s', '15s', '30s', '60s']
  if (!SUPPORTED_TF.includes(timeframe)) throw new Error("timeframe is not currently supported")
  const indexOfS = timeframe.indexOf('s')
  const parsedTimeframe = timeframe.substring(0, indexOfS)

  let candles = []
  let currCandle = {}
  for (let i = 0; i < tickData.length; i++) {
    let currTick = tickData[i]
    let currTickPrice = currTick.last_price
    const currTickTime = moment(currTick.timestamp).tz('Asia/Kolkata')
    //initialize if empty
    if(!currCandle.open) {
      currCandle.open = currTickPrice
    }
    if(!currCandle.low) {
      currCandle.low = currTickPrice
    }
    if(!currCandle.high) {
      currCandle.high = currTickPrice
    }
    if(!currCandle.close) {
      currCandle.close = currTickPrice
    }
    if(!currCandle.date) {
      currCandle.date = currTick.timestamp
    }

    if(currTickTime.seconds() % parsedTimeframe === 0) {
      candles.push({...currCandle})
      currCandle.open = currTickPrice
      currCandle.close = currTickPrice
      currCandle.low = currTickPrice
      currCandle.high = currTickPrice
      currCandle.date = currTick.timestamp
    }
    if(currTickPrice > currCandle.high) {
      currCandle.high = currTickPrice
    }
    if(currTickPrice < currCandle.low) {
      currCandle.low = currTickPrice
    }
    currCandle.close = currTickPrice
  }
  candles.push(currCandle)
  return candles
}

