
import React, {useCallback} from "react"
import {ButtonGroup, Button, Slider, Card, Tag, Switch} from "@blueprintjs/core"

const ControlsSection = ({onControl, onRefresh, mainDispatch, duplicateTrade, isIntraday, setIsIntraday, onSignOff, calculateM2M, onQuickTrigger}) => {

  const onQuickTriggerExitM2mLessThan = (m2mValue) => {
    onQuickTrigger('CREATE_EXIT_M2M_LESS_THAN_TRIGGER', {m2mValue})
  }

  return (
    <div className={"flex px-3"}>
      <div>
        <div className={'mb-4'}>
          <Card>
            <div className={'pb-4'}>
              EXIT_M2M_LESS_THAN
            </div>
            <div className={'flex gap-2 flex-wrap'}>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-3500)}>-3500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-2500)}>-2500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-2000)}>-2000</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-1500)}>-1500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-1200)}>-1200</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-1000)}>-1000</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-800)}>-800</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-500)}>-500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(-100)}>-100</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(0)}>0</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(100)}>100</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(500)}>500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(1000)}>1000</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(1500)}>1500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(2500)}>2500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(3500)}>3500</Tag>
              <Tag large interactive onClick={() => onQuickTriggerExitM2mLessThan(4500)}>4500</Tag>
            </div>
          </Card>
        </div>
        <Switch checked={isIntraday} inline label="MIS"
                onChange={(e) => setIsIntraday(!isIntraday)}/>
        <Button onClick = {onSignOff}>Sign Off Trade</Button>
        <Button onClick = {calculateM2M}>Calculate M2M</Button>
        <ButtonGroup>
          <Button onClick={() => {
            onControl({type: "BUY_MARKET", isIntraday})
          }}>BUY MARKET</Button>
          <Button>5k</Button>
          <Button>10k</Button>
          <Button>20k</Button>
          <Button>30k</Button>
          <Button>50k</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={() => {
            onControl({type: "SELL_MARKET", isIntraday})
          }}>SELL MARKET</Button>
          <Button>1x</Button>
          <Button>2x</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={() => {
            onControl({type: "SELL_MARKET", isIntraday})
          }}>SELL MARKET</Button>
          <Button>25%</Button>
          <Button>50%</Button>
          <Button>100%</Button>
        </ButtonGroup>
      </div>
      <div className={'mx-5 hidden'}>
          <Slider
          min={500}
          max={1500}
          stepSize={500}
          labelStepSize={500}
          value={1000}
          ></Slider>
      </div>
    </div>

  )
}
export default ControlsSection
