import {Button, Classes, Dialog, HTMLSelect, Label, Radio, RadioGroup} from "@blueprintjs/core"
import {useForm, Controller} from "react-hook-form"
import {useCallback} from "react"
import moment from "moment-timezone"


const CreateTradeModal = ({isOpen, strategies, onClose, initialState, handleCreateTrade}) => {
  let trade_id = ''
  if(initialState.strategy_id) {
    const strategy_id = initialState.strategy_id
    const today = moment();
    const dateString = today.format('HHmmss')
    trade_id = `${strategy_id}_${dateString}`
  }
  const { register, control, watch, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {...initialState, trade_id}
  });
  console.log(watch("strategy_id"));
  const generateTradeId = useCallback((e) => {
    const strategy_id = e
    const today = moment();
    const dateString = today.format('HHmmss')
    const trade_id = `${strategy_id}_${dateString}`
    setValue('trade_id', trade_id)
  }, [setValue])

  const onSubmit = useCallback((data) => {
    handleCreateTrade(data);
  }, [handleCreateTrade])
    return (
	  <div>
      <Dialog isOpen={isOpen} title={'Create Trade'} onClose={onClose}>
        <div className={Classes.DIALOG_BODY}>
          <form className={'flex flex-col'} onSubmit={handleSubmit(onSubmit)}>
            {/*strategy dropdown*/}
            <div className={'mb-4 w-full'}>
              <Label>
                Strategy Id
                <Controller
                  name="strategy_id"
                  control={control}
                  defaultValue={false}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <>
                        <HTMLSelect {...field} onChange={(e) => {
                          generateTradeId(e.target.value)
                          field.onChange(e)
                        }}>
                          {
                            strategies.map(s => (
                              <option key={s.strategy_id} value={s.strategy_id}>{s.strategy_id}</option>
                            ))
                          }
                        </HTMLSelect>
                        {errors.strategy_id && <span>This field is required</span>}
                      </>
                      )
                  }}
                />
              </Label>
            </div>


            <Label>
              Trade Id
              <div className={'mb-4 w-full'}>
                {/*pre populated trade id based on dropdown value e.g. for strategy bband, bband_21jun6*/}
                <input className={Classes.INPUT + ' w-full'} {...register('trade_id')}/>
                {errors.trade_id && <span>This field is required</span>}
              </div>
            </Label>

            <Label>
              Trade Plan
              <div className={'mb-4'}>
                {/*trade plan*/}
                <textarea className={Classes.INPUT + ' w-full h-96'} rows={15} {...register('trade_plan')}/>
              </div>
            </Label>

            <div className={'mb-4'}>
              {/*risk profile*/}
              <Controller
                name="risk_profile"
                control={control}
                defaultValue={false}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <>
                      <RadioGroup
                        label="Risk Profile"
                        selectedValue={field.value}
                        inline
                        {...field}
                      >
                        <Radio label="500" value="500" />
                        <Radio label="1000" value="1000" />
                        <Radio label="1500" value="1500" />
                      </RadioGroup>
                      {errors.risk_profile && <span>This field is required</span>}
                    </>
                  )
                }}
              />
            </div>
            <Button type="submit" text={'Submit'}/>
          </form>


        </div>
        <div className={Classes.DIALOG_FOOTER}>

        </div>

      </Dialog>
	  </div>
    )
}
export default CreateTradeModal
