import {useLocation} from "react-router"
import React, {useCallback, useEffect} from "react"
import axios from "axios"
import {setZAuth} from "../../App"


function useQuery(location) {
  return new URLSearchParams(location.search);
}
export default function Callback() {
  let location = useLocation();
  let query = useQuery(location);
  let request_token = query.get("request_token")
  //TODO need to disable auto revalidation?
  const getTokens = useCallback(async (request_token) => {
    const getTokenResponse = await axios.get('/tokens?request_token='+request_token)
    localStorage.setItem("zdha_access_token", getTokenResponse?.data?.access_token);
    setZAuth();
  }, [])
  useEffect(() => {
    if(request_token) {
      getTokens(request_token)
    }
  }, [request_token, getTokens])
  return (
    <div>
      Query: {JSON.stringify(location)}
    </div>
  )
}
