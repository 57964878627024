import React from 'react'

class Dashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      margin: 0
    }
  }
  
  render() {
    const {margin} = this.state;
    return (
      <div>
        <h2>Dashboard</h2>
        <p>Margin: {margin}</p>
      </div>
    )
  }
}

export default Dashboard