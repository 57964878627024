import React, {useCallback, useEffect, useReducer} from 'react';
import TradeWindow from "../../components/TradeWindow"
import chartService from "../../service/chartData"
import moment from "moment-timezone"
import {
  addInstrument,
  addLog,
  doTradeSignOff, doTradeSquareOff, getNonSignedOffTrades,
  getTriggers,
  postTrade,
  setTradeActive
} from "../../service/trades"
import {
  computePositions,
  getExchangeFromToken,
  getOrderDrawingsFromTrade,
  getSymbolFromToken, getTokenFromCombinedSymbol, getTriggerDrawingsFromTrade
} from "../../utils"
import ChartTopControls from "./components/ChartTopControls"
import {clearOrders, getOrders} from "../../service/orders"
import {placeMarketOrder} from "../../service/signal"
import ChartWrapper from "./components/ChartWrapper"
import {combinedReducer, initialState} from "./chartReducer"
import {getTriggerCreateActionFromChartOrder} from "./triggers/triggerUtils"
import CreateTradeModal from "../CreateTradeModal/CreateTradeModal"
import {getStrategies} from "../../service/strategies"
import ChartOptionsControls from "./components/ChartOptionsControls"
import ChartTradeWindow from "../../components/TradeWindow/ChartTradeWindow"
import {NonIdealState, Tag} from "@blueprintjs/core"
import {abortTrigger} from "../../service/triggers"
import AddTriggerModal from "../AddTriggerModal/AddTriggerModal"
import {getTriggerTypes} from "../../service/triggerTypes"
import {postAlgoAction} from "../../service/algoAction"
import {
  disconnectSocket,
  initiateSocket,
  setToken,
  subscribeToInitialTicks,
  subscribeToTicks
} from "../../sockets/socketManager"
import {addPaddingCandles} from "../../chartUtils"
import {convertTickToCandle} from "../../common/commonUtils"
// const useLiveData = false
const useLiveData = false
const MainContext = React.createContext({mainDispatch: null, markTradeActive: null, markOnChart: {}});
const ChartDemo = ({
                     instruments
                   }) => {

// Inside your component.
  const [mainState, dispatch] = useReducer(combinedReducer, initialState)
  const trades = mainState.trades.allTrades
  let openTrades = trades.filter(i => !i.sign_off)
  const {chartSettings, pickerPrice, chartData, inFocusTradeId, chartOrder, chartTickData} = mainState
  let inFocusTrade, inFocusTradeDrawings, activeInstrumentDrawings = []
  inFocusTrade = trades.find(i => i.trade_id === inFocusTradeId)
  const showWarning = inFocusTrade?.instruments && !inFocusTrade?.instruments.includes(mainState.chartSettings.token)
  if (inFocusTrade) {
    inFocusTradeDrawings = inFocusTrade.drawings ? inFocusTrade.drawings : []
    activeInstrumentDrawings = inFocusTradeDrawings[chartSettings.token] ? inFocusTradeDrawings[chartSettings.token] : []
    //add order drawings
    const orderDrawings = getOrderDrawingsFromTrade(inFocusTrade, mainState.markOnChart, mainState.chartSettings.token)
    const triggerDrawings = getTriggerDrawingsFromTrade(inFocusTrade, mainState.markOnChart, mainState.chartSettings.token)
    if (orderDrawings[chartSettings.token]) {
      activeInstrumentDrawings = [...activeInstrumentDrawings, ...orderDrawings[chartSettings.token]]
    }
    if (triggerDrawings[chartSettings.token]) {
      activeInstrumentDrawings = [...activeInstrumentDrawings, ...triggerDrawings[chartSettings.token]]
    }
    //add trigger drawings
    //make focussed trade as first
    openTrades.forEach(function (item, i) {
      if (item.trade_id === inFocusTradeId) {
        openTrades.splice(i, 1);
        openTrades.unshift(item);
      }
    });
  }

  const updateLiveData = useCallback( () => {
    const {interval} = mainState.chartSettings
    if (chartTickData) {
      const ohlc = convertTickToCandle(chartTickData, interval)
      const ohlcWithPaddedCandles = addPaddingCandles(ohlc)
      dispatch({type: "CHART_DATA_RECEIVED", chartData: ohlcWithPaddedCandles})
    }
  }, [mainState.chartSettings, chartTickData])
  const getChartData = useCallback(async () => {
    const {token, interval, liveData} = mainState.chartSettings
    if(liveData) {
      updateLiveData()
    } else {
      const today = moment().format("YYYY-MM-DD")
      const twoDaysAgo = moment().subtract(4, "days").format("YYYY-MM-DD")
      const chartDataRes = await chartService.getData(twoDaysAgo, today, token, interval)
      dispatch({type: "CHART_DATA_RECEIVED", chartData: chartDataRes.data})
    }
  }, [mainState.chartSettings, mainState.chartSettings, updateLiveData])

  const getTradesData = useCallback(async () => {
    const getTradesRes = await getNonSignedOffTrades()
    let trades = getTradesRes.data
    trades = await Promise.all(trades.map(async trade => {
      const trade_id = trade.trade_id
      const ordersRes = await getOrders(trade_id)
      const triggersRes = await getTriggers(trade_id)
      const triggers = triggersRes.data
      const orders = ordersRes.data
      const positions = computePositions(orders)
      return {...trade, positions, triggers, allOrders: orders}
    }))
    dispatch({type: "TRADES_DATA_RECEIVED", tradesData: trades})
  }, [])

  const refreshTrade = useCallback(async (trade_id) => {
    const ordersRes = await getOrders(trade_id)
    const triggersRes = await getTriggers(trade_id)
    const triggers = triggersRes.data
    const orders = ordersRes.data
    console.log('orders: ', orders)
    const positions = computePositions(orders)
    dispatch({type: "TRADE_DETAIL_RECEIVED", trade_id, positions, triggers, allOrders: orders})
  }, [])

  const addTradeLog = useCallback(async (trade_id, log) => {
    await addLog(trade_id, log)
    await getTradesData()
  }, [getTradesData])

  const loadStrategies = useCallback(async () => {
    const strategies = await getStrategies()
    dispatch({type: "STRATEGIES_DATA_RECEIVED", strategies: strategies.data})
  }, [])

  const loadTriggerTypes = useCallback(async () => {
    const triggerTypes = await getTriggerTypes()
    console.log('triggerTypes: ', triggerTypes)
    dispatch({type: "TRIGGER_TYPES_DATA_RECEIVED", triggerTypes: triggerTypes.data})
  }, [])

  const loadAll = useCallback(async () => {
    await loadStrategies()
    await loadTriggerTypes()
    await getTradesData()
    await getChartData()
  }, [getTradesData, getChartData, loadStrategies, loadTriggerTypes])

  const focusNextTrade = useCallback(async (inFocusTrade) => {
    if (inFocusTrade) {
      const focusTradeId = inFocusTrade.trade_id
      const sortedOpenTrades = openTrades.sort((a, b) => a.trade_id.localeCompare(b.trade_id))
      const index = sortedOpenTrades.findIndex((t) => t.trade_id === focusTradeId)
      const nextIndex = (index + 1) % sortedOpenTrades.length
      dispatch({type: "SET_FOCUS_TRADE", trade_id: sortedOpenTrades[nextIndex].trade_id})
    }
  }, [inFocusTrade])

  const getProductType = (exchange, isIntraday) => {
    if (isIntraday) return 'MIS'
    else return exchange === 'NSE' ? 'CNC' : 'NRML'
  }
  const onNetworkActionControl = useCallback(async (action) => {
    try {
      switch (action.type) {
        case 'BUY_MARKET': {
          const chartToken = mainState.chartSettings.token
          const symbol = getSymbolFromToken(instruments, chartToken)
          const exchange = getExchangeFromToken(instruments, chartToken)
          const instrumentInfo = instruments.find(i => i.instrument_token === chartToken)
          const quantity = instrumentInfo.lot_size
          const product = getProductType(exchange, action.isIntraday)
          await placeMarketOrder(mainState.inFocusTradeId, exchange, symbol, true, quantity, product)
          break;
        }
        case 'SELL_MARKET': {
          const chartToken = mainState.chartSettings.token
          const symbol = getSymbolFromToken(instruments, chartToken)
          const exchange = getExchangeFromToken(instruments, chartToken)
          const instrumentInfo = instruments.find(i => i.instrument_token === chartToken)
          const quantity = instrumentInfo.lot_size
          const product = getProductType(exchange, action.isIntraday)
          await placeMarketOrder(mainState.inFocusTradeId, exchange, symbol, false, quantity, product)
          break;
        }
        case 'SIGN_OFF_TRADE': {
          await doTradeSignOff(action.trade_id, action.doSignOff)
          break;
        }
        case 'SQUARE_OFF_TRADE': {
          await doTradeSquareOff(action.trade_id)
          break;
        }
        case 'ABORT_TRIGGER': {
          await abortTrigger(action.trade_id, action.trigger_id)
          break;
        }
        case 'SWITCH_CHART': {
          const token = getTokenFromCombinedSymbol(instruments, action.combinedSymbol)
          if (token) {
            dispatch({type: 'SET_CHART_TOKEN', token})
          } else {
            console.error(`could not find token with this combined symbol. symbol: ${action.combinedSymbol}`)
          }
          break;
        }
        case 'CREATE_ALGO_ACTION': {
          await postAlgoAction(action.algoAction)
          break;
        }
        default:
          console.log('unknown trade control ')
      }
      await loadAll();
    } catch (e) {
      console.log('Error in onTradeControl', e)
    }
  }, [mainState.chartSettings.token, instruments, mainState.inFocusTradeId, loadAll])


  const onClearOrders = useCallback(async (trade_id) => {
    await clearOrders(trade_id)
    await getTradesData()
  }, [getTradesData])

  const onCreateTradeModalClose = useCallback(async () => {
    dispatch({type: 'HIDE_CREATE_TRADE_MODAL'})
  }, [])

  const onAddTriggerModalClose = useCallback(async () => {
    dispatch({type: 'HIDE_ADD_TRIGGER_MODAL'})
  }, [])

  const markTradeActive = useCallback(async (trade_id, setActive) => {
    await setTradeActive(trade_id, setActive)
    await getTradesData()
  }, [getTradesData])

  const addInstrumentToTrade = useCallback(async () => {
    await addInstrument(mainState.inFocusTradeId, mainState.chartSettings.token)
    await getTradesData()
  }, [getTradesData, mainState.chartSettings.token, mainState.inFocusTradeId])

  const handleCreateTrade = useCallback(async (trade) => {
    await postTrade(trade)
    await onCreateTradeModalClose()
  }, [onCreateTradeModalClose])

  const duplicateTrade = useCallback(async (trade_id) => {
    const inFocusTrade = mainState.trades.allTrades.find(t => {
      return t.trade_id === trade_id
    })
    const {strategy_id, risk_profile, trade_plan} = inFocusTrade
    const formState = {strategy_id, risk_profile, trade_plan: trade_plan.plan}
    console.log('formState: ', formState)
    dispatch({type: 'DUPLICATE_TRADE', formState})
  }, [mainState.trades.allTrades])
  const onAddTriggerModalSubmit = useCallback(async (triggerTypeId, triggerParams) => {
    let algoActionType
    switch (triggerTypeId) {
      case 'EXIT_M2M_LESS_THAN': {
        algoActionType = 'CREATE_EXIT_M2M_LESS_THAN_TRIGGER'
        break;
      }
      default: {
        console.log('trigger type match not found')
      }
    }
    if(!inFocusTrade) {
      console.log('Error: no trade in focus')
      return;
    }
    const {trade_id} = inFocusTrade
    await postAlgoAction({
      "algo_trade_id": trade_id,
      "algo_action_type": algoActionType,
      "algo_action_info": {...triggerParams}
    })
    dispatch({type: 'HIDE_ADD_TRIGGER_MODAL'})
  }, [inFocusTrade])

  useEffect(() => {
    initiateSocket(mainState.chartSettings.token);
    subscribeToTicks((err, data) => {
      if(err || !data) return;
      dispatch({type: 'CHART_TICK_RECEIVED', tick: data})
    });
    subscribeToInitialTicks((err, data) => {
      console.log('CHART_INITIAL_TICKS_RECEIVED')
      const recdData = data ? data : []
      if(err) return;
      dispatch({type: 'CHART_INITIAL_TICKS_RECEIVED', initialTicks: recdData})
    });
    return () => {
      disconnectSocket();
    }
  }, []);

  useEffect(() => {
    const {markOnChart, chartSettings} = mainState
    if(inFocusTrade) {
      const orderIdList = markOnChart.orders[inFocusTrade.trade_id] ? markOnChart.orders[inFocusTrade.trade_id] : []
      const tradeOrders = inFocusTrade.allOrders
      const instrumentOrders = tradeOrders.filter(o => o.instrument_token === chartSettings.token)
      //find orders not marked ever (if such are found, mark them)
      const neverMarked = instrumentOrders.filter(o => !Object.keys(orderIdList).includes(o.log_id) && o.status === 'COMPLETE')
      neverMarked.forEach(o => {
        dispatch({type: 'MARK_ORDER_ON_CHART', trade_id: o.trade_id, log_id: o.log_id})
      })
      //no need to wait for the promise
      getChartData()
    }
  }, [inFocusTrade, mainState.markOnChart, getChartData])

  //send live data to chart data if live chart mode is on
  useEffect(() => {
    if(mainState.chartSettings.liveData) {
      updateLiveData()
    }
  }, [mainState.chartSettings, chartTickData]);

  useEffect(() => {
    setToken(mainState.chartSettings.token)
  }, [mainState.chartSettings.token]);
  //check if chartOrder info seems complete, and trigger order if yes.
  useEffect(() => {
    if (mainState.chartOrder) {
      const {step, nSteps, quantity} = mainState.chartOrder
      // step equals nSteps
      if (step === nSteps && mainState.inFocusTradeId) {
        const token = mainState.chartSettings.token
        const symbol = getSymbolFromToken(instruments, token)
        const exchange = getExchangeFromToken(instruments, token)
        const instrument = {token, exchange, symbol}
        console.log('mainState.inFocusTradeId: ', mainState.inFocusTradeId)
        //create trigger
        getTriggerCreateActionFromChartOrder(mainState.chartOrder, {instrument, trade_id: mainState.inFocusTradeId}, quantity, onNetworkActionControl)
        //reset chartOrder state
        dispatch({type: 'CANCEL_CHART_ORDER'})
      }
    }
  }, [mainState.chartOrder, instruments, mainState.inFocusTradeId, mainState.chartSettings.token])

  //set chart name
  useEffect(() => {
    if (mainState.createTradeForm.modalOpen) {
    }
  }, [mainState.createTradeForm.modalOpen])

  //set chart name
  useEffect(() => {
    const token = mainState.chartSettings.token
    if (instruments && instruments.length > 0 && token) {
      const instrumentInfo = instruments.filter(i => i.instrument_token === token)[0]
      dispatch({type: "SET_CHART_NAME", name: instrumentInfo.tradingsymbol})
    }
  }, [mainState.chartSettings.token, instruments])

  //initial load
  useEffect(() => {
    (async () => {
      await loadAll();
    })();
  }, [])

  return (
    <MainContext.Provider value={{mainDispatch: dispatch, markTradeActive, markOnChart: mainState.markOnChart}}>
      {mainState.createTradeForm.modalOpen ? (
        <CreateTradeModal isOpen={mainState.createTradeForm.modalOpen} strategies={mainState.strategies}
                          initialState={mainState.createTradeForm.formState} onClose={onCreateTradeModalClose}
                          handleCreateTrade={handleCreateTrade}/>
      ) : ('')}
      {mainState.addTriggerForm.modalOpen ? (
        <AddTriggerModal isOpen={mainState.addTriggerForm.modalOpen} triggerTypes={mainState.triggerTypes}
                         initialState={mainState.addTriggerForm.formState} onClose={onAddTriggerModalClose}
                         handleCreateTrade={onAddTriggerModalSubmit}/>
      ) : ('')}
      <div>
        <div className={'core-container'}>
          <div className={'chart-section'}>
            <ChartWrapper
              chartData={chartData}
              chartName={chartSettings.name}
              interval={chartSettings.interval}
              instrumentToken={mainState.chartSettings.token}
              drawings={activeInstrumentDrawings}
              mainDispatch={dispatch}
              zoomEnabled={mainState.chartSettings.zoomEnabled}
              panEnabled={mainState.chartSettings.panEnabled}
            />
            <ChartTopControls instruments={instruments} chartOrder={chartOrder} onRefreshAll={loadAll}
                              onAddInstrument={addInstrumentToTrade} chartSettings={chartSettings} showWarning={showWarning}/>
            <ChartOptionsControls/>
          </div>
          <div className={'trade-window-section'}>
            <div className={'quick-trade-selector p-2 flex space-x-2 space-y-3'}>
              {
                openTrades.map(({trade_id, trade_plan}) => {
                  return (
                    <div key={trade_id}>
                      <Tag interactive onClick={() => dispatch({type: "SET_FOCUS_TRADE", trade_id})}>
                        <div>
                          <p>{trade_id}</p>
                          <p>{trade_plan?.plan?.slice(0, 20)}</p>
                        </div>
                      </Tag>
                    </div>
                  )
                })
              }
            </div>
            <div className={'border-t pt-2'}>
              {
                inFocusTrade ? (
                  <ChartTradeWindow
                    key={inFocusTrade.trade_id}
                    onRefresh={() => refreshTrade(inFocusTrade.trade_id)}
                    onFocusNextTrade={() => focusNextTrade(inFocusTrade)}
                    onClearOrders={() => onClearOrders(inFocusTrade.trade_id)}
                    trade_id={inFocusTrade.trade_id}
                    pickerPrice={inFocusTradeId === inFocusTrade.trade_id ? pickerPrice : null}
                    trade={inFocusTrade}
                    inFocus={inFocusTrade.trade_id === inFocusTradeId}
                    onControl={onNetworkActionControl}
                    mainDispatch={dispatch}
                    duplicateTrade={duplicateTrade}
                    addTradeLog={addTradeLog}
                  />
                ) : (
                  <div className={'mt-32'}>
                    <NonIdealState
                      icon={"search"}
                      title="No trade in focus"
                      description={'Set focus on a trade'}
                    />
                  </div>
                )
              }
            </div>

          </div>
        </div>
        <div className={'trade-window-container mt-60'}>
          {
            openTrades.map(trade => (
              <div className={'mb-16'}>
                <TradeWindow
                  key={trade.trade_id}
                  onRefresh={() => refreshTrade(trade.trade_id)}
                  onClearOrders={() => onClearOrders(trade.trade_id)}
                  trade_id={trade.trade_id}
                  pickerPrice={inFocusTradeId === trade.trade_id ? pickerPrice : null}
                  trade={trade}
                  inFocus={trade.trade_id === inFocusTradeId}
                  onControl={onNetworkActionControl}
                  mainDispatch={dispatch}
                  duplicateTrade={duplicateTrade}
                />
              </div>
            ))
          }
        </div>
      </div>
    </MainContext.Provider>
  )

}
export default ChartDemo
export {MainContext}

