import React, {useContext, useState} from 'react'
import {Popover2} from "@blueprintjs/popover2"
import {Button, Checkbox, Icon, Tag} from "@blueprintjs/core"
import {MainContext} from "../../../views/Chart/ChartDemoHook"

const TriggersTable = ({triggers, trade, onControl}) => {
  const {mainDispatch, markOnChart } = useContext(MainContext)
  const [showInactive, setShowInactive] = useState(false)
  let onlyActive, toShow = [...triggers]
  onlyActive = triggers.filter(({triggerDetails: {status}}) => !status.includes('ABORTED') && !status.includes('CLOSED'))
  if(!showInactive) {
    toShow = onlyActive
  }
    return (
      <div className={'mt-6'}>
        <div>
          <Checkbox label={'Show inactive'} checked={showInactive} onChange={() => setShowInactive(!showInactive)}/>
        </div>
        <div className={''}>
          {
            toShow && toShow.map(trigger => {
              const {trigger_id} = trigger
              const {trade_id} = trade
              const isOnChart = markOnChart.triggers[trade_id]&&markOnChart.triggers[trade_id][trigger_id]
              return (
                <div className={'py-2'}>
                  <Tag
                    onRemove={() => onControl({
                      type: 'CREATE_ALGO_ACTION',
                      algoAction: {
                        "algo_trade_id": trade_id,
                        "algo_action_type": "ABORT_TRIGGER",
                        "algo_action_info": {
                          "trigger_id": trigger_id
                        }
                      }
                    })}
                    icon={<Icon icon={!!isOnChart ? 'chart' : ''} className={'text-gray-500 px-1'}/>}
                    onClick={(e) => {
                      const action = !!isOnChart ? 'UNMARK_TRIGGER_ON_CHART' : 'MARK_TRIGGER_ON_CHART'
                      mainDispatch({type: action, trade_id, trigger_id})
                    }}
                    interactive
                    fill
                  >
                    <div className={'flex gap-2'}>
                      <div className={'flex items-center gap-x-3 text-gray-500'}>
                        <Popover2
                          enforceFocus={false}
                          content={<div
                            style={{maxWidth: '500px', padding: '15px'}}>{JSON.stringify(trigger, null, 4)}</div>}
                          interactionKind={'hover'}
                        >
                          <div><Icon icon={'info-sign'}/>{' '}</div>
                        </Popover2>
                        <Popover2
                          enforceFocus={false}
                          content={<div
                            style={{maxWidth: '500px', padding: '15px'}}>{JSON.stringify(trigger?.params, null, 4)}</div>}
                          interactionKind={'hover'}
                        >
                          <div><Icon icon={'settings'}/>{' '}</div>
                        </Popover2>
                      </div>
                      <div>
                        <div>
                          {trigger.type}
                        </div>
                        <div>
                          {trigger.triggerDetails.status}
                        </div>
                      </div>
                    </div>
                  </Tag>
                </div>
              )
            })
          }
        </div>
      </div>
    )
}
export default TriggersTable
