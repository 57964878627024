import styled from 'styled-components'
import moment from "moment"
import Header from "./components/Header"
import React, {useCallback, useState} from "react"
import OrdersTable from "./components/OrdersTable"
import ControlsSection from "./components/ControlsSection"
import PositionsTable from "./components/PositionsTable"
import {Popover2} from "@blueprintjs/popover2"
import {computeM2M} from "../../utils"
import {Alert, Callout} from "@blueprintjs/core"
const M2MDisplay = ({m2m}) => {
  const keys = Object.keys(m2m)
  return(
    <div>
      {
        keys.map((key) => (
          <div key={key}>
            {key} {'==>'} {m2m[key].toFixed(2)}
          </div>
        ))
      }

    </div>
  )
}

const TradePlan = ({trade}) => {
  return (
    <div>
      <p>
        {trade.trade_plan.plan}
      </p>
      {
        trade.trade_plan.logs.map((log) => {
          return (
            <p>{log.time} {log.message}</p>
          )
        })
      }
    </div>
  )
}

const StyledTradeWindow = styled.div`
  border: ${props => props.inFocus ? "3px solid blue" : "1px solid grey"};
  border-radius: 4px;
  padding: 20px;
  margin: 20px;
`
const TradeWindow = ({
                       trade_id,
                       inFocus,
                       onClearOrders,
                       onRefresh,
                       trade,
                       onControl,
                       mainDispatch,
                       duplicateTrade
                     }) => {
  const [m2m, setM2M] = useState({})
  const [showM2M, setShowM2M] = useState(false)
  const calculateM2M = useCallback(async () => {
    const m2m = await computeM2M(trade.allOrders);
    const now = moment()
    setM2M({lastUpdated: now, m2m})
    setShowM2M(true)
  }, [trade.allOrders])
  return (
    <Callout>
      <div className={`border rounded ${inFocus ? 'border-blue-500' : 'border-gray-200'}`}>
        <Header
          trade_id={trade_id}
          strategy_id={trade.strategy_id}
          onRefresh={onRefresh}
          tradeActive={trade.active}
          onClearOrders={onClearOrders}
          inFocus={inFocus}
        ></Header>
        <Alert isOpen={showM2M} onClose={() => setShowM2M(false)}>
          <M2MDisplay m2m={m2m.m2m}/>
        </Alert>
        <div className={'trade-detail px-3'}>
          <TradePlan trade={trade}/>
        </div>
        <div>
          <OrdersTable orders={trade.allOrders}/>
          <PositionsTable positions={trade.positions}/>
          <table className={"bp3-html-table bp3-small mt-6"}>
            <thead>
            <tr>
              <th>Type</th>
              <th>Trigger Price</th>
              <th>BUY/SELL</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {
              trade.triggers.map(trigger => {
                return (
                  <tr key={trigger.trigger_id}>
                    <td>
                      <Popover2
                        enforceFocus={false}
                        content={<div
                          style={{maxWidth: '500px', padding: '15px'}}>{JSON.stringify(trigger, null, 4)}</div>}
                        interactionKind={'hover'}
                      >
                        {trigger.type}
                      </Popover2>
                    </td>
                    {
                      trigger.triggerDetails.order ? (
                        <>
                          <td>{trigger.triggerDetails?.order?.triggerPrice}</td>
                          <td>{trigger.triggerDetails?.order?.goLong ? 'BUY' : 'SELL'}</td>
                        </>
                      ) : (
                        <>
                          <td/>
                          <td/>
                        </>
                      )
                    }
                    <td>{trigger.triggerDetails.status}</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
        <ControlsSection onControl={onControl} onRefresh={onRefresh} duplicateTrade={() => duplicateTrade(trade_id)}
                         onSignOff={() => onControl({type: 'SIGN_OFF_TRADE', trade_id, doSignOff: true})} calculateM2M={calculateM2M}/>
      </div>
    </Callout>
  )
}

export default TradeWindow
