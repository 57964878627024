import moment from "moment"
import React, {useContext} from "react"
import {Checkbox, Icon, Intent, Tag} from "@blueprintjs/core"
import {MainContext} from "../../../views/Chart/ChartDemoHook"
import { Tooltip2} from "@blueprintjs/popover2"


const CombinedSymbol = ({tradeOrder: {tradingsymbol, transaction_type, order_type, status, quantity, product, price}}) => {
  let intent = Intent.NONE
  intent = status === 'COMPLETE' ? (transaction_type === 'BUY' ? Intent.SUCCESS : Intent.DANGER) : intent
  return (
    <Tooltip2
      content={'hello'}
    >

      <div className={'flex flex-col'}>
        <Tag
          minimal
          intent={intent}
        >
          {tradingsymbol} x{quantity}
        </Tag>
        <Tag minimal>
          {order_type === 'MARKET' ? 'MKT ' : 'LMT '}
          {product}{' '}
          {status}
        </Tag>
        {
          price ? (
            <Tag minimal>
              {price}
            </Tag>
          ) : ''
        }
      </div>

    </Tooltip2>
  )
}
const OrdersTable = ({orders}) => {
  const {mainDispatch, markOnChart } = useContext(MainContext)
  return (
    <div>
      <table className={"bp3-html-table bp3-small mt-6"}>
        <thead>
        <tr>
          <th><Icon icon={'chart'}/></th>
          <th>Time</th>
          <th>Symbol</th>
        </tr>
        </thead>
        <tbody>
        {
          orders.map(tradeOrder => {
            const {trade_id, log_id} = tradeOrder
            const isOnChart = markOnChart.orders[trade_id]&&markOnChart.orders[trade_id][log_id]
            return (
              <tr key={log_id}>
                <td>
                  <Checkbox checked={!!isOnChart} label="" onChange={(e) => {
                    const action = e.target.checked ? 'MARK_ORDER_ON_CHART' : 'UNMARK_ORDER_ON_CHART'
                    mainDispatch({type: action, trade_id: tradeOrder.trade_id, log_id: tradeOrder.log_id})
                  }} />
                </td>
                <td>{moment(tradeOrder.action_time).format("HH:mm:ss")}</td>
                <td><CombinedSymbol tradeOrder={tradeOrder}/></td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  )
}
export default OrdersTable
