import {Button, TextArea} from "@blueprintjs/core"
import React, {useState} from "react"

const TradePlan = ({trade, addTradeLog}) => {
  const [newLog, setNewLog] = useState('')
  return (
    <div className={'w-full'}>
      <p>
        {trade.trade_plan.plan}
      </p>
      <>
        {
          trade.trade_plan.logs.map((log) => {
            return (
              <p>{log.time} {log.message}</p>
            )
          })
        }
        <div className={'w-full flex space-x-2'}>
          <TextArea className={'w-full'} value={newLog} onChange={(e) => setNewLog(e.target.value)}/>
          <Button large onClick={() => {
            addTradeLog(trade.trade_id, newLog)
            setNewLog('')
          }}> Send </Button>
        </div>
      </>
    </div>
  )
}
export default TradePlan

