import {Button, Classes, Dialog, HTMLSelect, Label, Radio, RadioGroup} from "@blueprintjs/core"
import {useForm, Controller} from "react-hook-form"
import {useCallback} from "react"
import moment from "moment-timezone"
import ReactJson from "react-json-view"
import {getExitM2mLessThanTrigger} from "../../common/triggerUtils"


const AddTriggerModal = ({isOpen, triggerTypes, onClose, initialState, handleCreateTrade}) => {
  let trade_id = ''
  const { register, control, watch, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {...initialState, trigger_type_id: triggerTypes[0].trigger_type_id}
  });
  console.log(watch("trigger_type_id"));
  console.log('triggerTypes: ', triggerTypes)
  const selectedTriggerTypeId = getValues("trigger_type_id")
  const selectedTriggerType = triggerTypes.find(i => i.trigger_type_id === selectedTriggerTypeId)
  console.log('selectedTriggerType: ', selectedTriggerType)

  const onSubmit = useCallback((data) => {
    console.log('data: ', data)
    handleCreateTrade(data.trigger_type_id, data.trigger_params);
  }, [handleCreateTrade])
    return (
	  <div>
      <Dialog isOpen={isOpen} title={'Add Trigger'} onClose={onClose}>
        <div className={Classes.DIALOG_BODY}>
          <form className={'flex flex-col'} onSubmit={handleSubmit(onSubmit)}>
            {/*trigger dropdown*/}
            <div className={'mb-4 w-full'}>
              <Label>
                Trigger Type
                <Controller
                  name="trigger_type_id"
                  control={control}
                  defaultValue={false}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <>
                        <HTMLSelect {...field} onChange={(e) => {
                          field.onChange(e)
                        }}>
                          {
                            triggerTypes.map(t => (
                              <option key={t.trigger_type_id} value={t.trigger_type_id}>{t.trigger_type_id}</option>
                            ))
                          }
                        </HTMLSelect>
                        {errors.trigger_type_id && <span>This field is required</span>}
                      </>
                      )
                  }}
                />
              </Label>
            </div>
            {/*params*/}
            <div className={'mb-4 w-full'}>
              <Label>
                Parameters
                <Controller
                  name="trigger_params"
                  control={control}
                  defaultValue={selectedTriggerType.initialState.params}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <>
                        <ReactJson src={selectedTriggerType?.initialState?.params} onEdit={(edit) => field.onChange(edit.updated_src)}/>
                        {errors.trigger_params && <span>This field is required</span>}
                      </>
                    )
                  }}
                />
              </Label>
            </div>
            <Button type="submit" text={'Submit'}/>
          </form>
        </div>
        <div className={Classes.DIALOG_FOOTER}>

        </div>

      </Dialog>
	  </div>
    )
}
export default AddTriggerModal
