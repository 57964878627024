import React from 'react'
import axios from "axios"
import {Button} from "@blueprintjs/core"

const config = require("./../config")

const handleLogin = () => {
  const login_url = `https://kite.trade/connect/login?api_key=${config.API_KEY}`
  window.location.replace(login_url)
}
const getCachedToken = () => {
  const cachedTokensUrl = `${config.BASE_URL}/tokens/cached`
  axios.get(cachedTokensUrl)
    .then(res => {
      const token = res.data
      localStorage.setItem('zdha_access_token', token)
      axios.defaults.headers.common['zat'] = token
    })
    .catch(err => {
      console.log('error: ', err)
    })
}
const Login = () => {
    return (
	  <div>
      Welcome to Login
      <Button className={'m-2'} onClick={handleLogin}>Initiate login</Button>
      <Button className={'m-2'} onClick={getCachedToken}>Fetch cached token</Button>
	  </div>
    )
}
export default Login
