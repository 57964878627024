import React, {useContext} from 'react'
import {Button, ButtonGroup, Icon, Menu, MenuItem, Popover, Position, Switch} from "@blueprintjs/core"
import {MainContext} from "../../../views/Chart/ChartDemoHook"
import {MinimalButton} from "../../../views/Chart/components/Button"

const AdvancedMenu = ({onClearOrders, calculateM2M, onSignOff, onSquareOff}) => (
  <Menu>
    <MenuItem text="Calculate M2M" onClick={calculateM2M}/>
    <Menu.Item text="Advanced..." icon="cog">
      <MenuItem text="Clear orders" onClick={onClearOrders}/>
      <MenuItem text="Square off" onClick={onSquareOff}/>
      <MenuItem text="Sign off" onClick={onSignOff}/>
    </Menu.Item>
  </Menu>
)
const Header = ({
                  trade_id,
                  strategy_id,
                  onRefresh,
                  tradeActive,
                  onClearOrders,
                  inFocus,
                  duplicateTrade,
                  calculateM2M,
                  onSignOff,
                  onSquareOff,
                  showControls,
                  setShowControls,
                  showOrders,
                  setShowOrders,
                  showPositions,
                  setShowPositions,
                  showTriggers,
                  setShowTriggers,
                  onFocusNextTrade
                }) => {
  const {markTradeActive, mainDispatch} = useContext(MainContext)
  const toFocusId = !inFocus ? trade_id : null
  return (
    <div className={'mb-8'}>
      <div className={'flex justify-between mt-1'}>
        <div className={'space-x-2 px-4'}>
          <span className={'bp3-heading'}>{strategy_id}</span> {' '}
          <span className={'mr-5'}>{trade_id}</span>
        </div>
      </div>
      <div className={'flex justify-between my-2'}>
        <div className={'space-x-4 px-4'}>
          <MinimalButton icon={"caret-right"} large className={""} onClick={onFocusNextTrade}/>
          <MinimalButton icon={"refresh"} large className={""} onClick={onRefresh}/>
          <MinimalButton icon={"duplicate"} large className={""} onClick={duplicateTrade}/>
          <MinimalButton icon={`${inFocus ? 'eye-on' : 'eye-off'}`} large className={""}
                         onClick={() => mainDispatch({type: "SET_FOCUS_TRADE", trade_id: toFocusId})}/>
        </div>
        <div className={"px-4"}>
          <Popover
            content={<AdvancedMenu onClearOrders={onClearOrders} calculateM2M={calculateM2M} onSignOff={onSignOff} onSquareOff={onSquareOff}/>}
            position={Position.BOTTOM}>
            <Button icon="more" minimal large/>
          </Popover>
        </div>
      </div>
      <div className={'border-t border-b pt-2'}>
        <div className={'px-4 flex justify-between'}>
          <div className={'mt-1.5'}>
            <Switch checked={tradeActive} inline label="Active"
                    onChange={(e) => markTradeActive(trade_id, e.target.checked)}/>
          </div>
          <div>
            <ButtonGroup>
              <Button minimal icon={<Icon icon={"properties"} color={`${showOrders ? 'black' : 'gray'}`} />} onClick={() => setShowOrders(!showOrders)}/>
              <Button minimal icon={<Icon icon={"code-block"} color={`${showTriggers ? 'black' : 'gray'}`} />} onClick={() => setShowTriggers(!showTriggers)}/>
              <Button minimal icon={<Icon icon={"control"} color={`${showControls ? 'black' : 'gray'}`} />} onClick={() => setShowControls(!showControls)}/>
              <Button minimal icon={<Icon icon={"selection"} color={`${showPositions ? 'black' : 'gray'}`} />} onClick={() => setShowPositions(!showPositions)}/>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
