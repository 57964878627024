//instrument_type CE, PE, FUT, EQ
//product_type CNC, NRML, MIS
import {getLTP} from "./service/kc"

export const charges = (amount, buy_sell, instrument_type, product_type) => {
  let charge = {}
  if(product_type === "CNC" && instrument_type === "EQ") {
    const brokerage = 0;
    const exchange_transaction = 0.00345 * 0.01 * amount;
    charge =  {
      brokerage,
      sttctt: buy_sell === "S" ? 0.1 * 0.01 * amount : 0,
      exchange_transaction,
      igst: 0.18 * 0.01 * (brokerage + exchange_transaction),
      sebi: 0.00005 * 0.01 * amount,
      stamp: buy_sell === "B" ? 0.015 * 0.01 * amount : 0
    }
  } else if (instrument_type === "EQ" && product_type === "MIS") {
    const brokerage = Math.min(20, 0.03 * 0.01 * amount);
    const exchange_transaction = 0.00345 * 0.01 * amount;
    charge =  {
      brokerage,
      sttctt: buy_sell === "S" ? 0.025 * 0.01 * amount : 0,
      exchange_transaction,
      igst: 0.18 * 0.01 * (brokerage + exchange_transaction),
      sebi: 0.00005 * 0.01 * amount,
      stamp: buy_sell === "B" ? 0.003 * 0.01 * amount : 0
    }

  } else if (instrument_type === "CE" || instrument_type === "PE") {
    const brokerage = 20;
    const exchange_transaction = 0.053 * 0.01 * amount;
    charge =  {
      brokerage,
      sttctt: buy_sell === "S" ? 0.05 * 0.01 * amount : 0,
      exchange_transaction,
      igst: 0.18 * 0.01 * (brokerage + exchange_transaction),
      sebi: 0.00005 * 0.01 * amount,
      stamp: buy_sell === "B" ? 0.003 * 0.01 * amount : 0
    }

  } else if (instrument_type === "FUT") {
    const brokerage = Math.min(20, 0.03 * 0.01 * amount);
    const exchange_transaction = 0.002 * 0.01 * amount;
    charge =  {
      brokerage,
      sttctt: buy_sell === "S" ? 0.01 * 0.01 * amount : 0,
      exchange_transaction,
      igst: 18 * 0.01 * (brokerage + exchange_transaction),
      sebi: 0.00005 * 0.01 * amount,
      stamp: buy_sell === "B" ? 0.002 * 0.01 * amount : 0
    }
  }
  charge.total = charge.brokerage + charge.sttctt + charge.exchange_transaction
    + charge.igst + charge.sebi + charge.stamp;
  return charge;
}
export const getToken = () => localStorage.getItem("zdha_access_token")

export const combineReducers = (reducers, initialState) =>
  (state = initialState, action) => {
  for(let i=0;i<reducers.length;i++) {
    state = reducers[i](state, action)
  }
  return state;
}

export const getSymbolFromToken = (instruments, token) => {
  const matchingEntries = instruments.filter(i => {
    return i.instrument_token === token
  })
  return matchingEntries[0].tradingsymbol
}
export const getExchangeFromToken = (instruments, token) => {
  const matchingEntries = instruments.filter(i => i.instrument_token === token)
  return matchingEntries[0].exchange
}
export const getCombinedSymbolFromToken = (instruments, token) => {
  const matchingEntries = instruments.filter(i => i.instrument_token === token)
  return `${matchingEntries[0].exchange}:${matchingEntries[0].tradingsymbol}`
}
export const getTokenFromCombinedSymbol = (instruments, combinedSymbol) => {
  const colonIndex = combinedSymbol.indexOf(':')
  const exchange = combinedSymbol.substr(0, colonIndex)
  const tradingsymbol = combinedSymbol.substr(colonIndex + 1)
  const matchingEntry = instruments.find(i => i.exchange === exchange && i.tradingsymbol === tradingsymbol)
  return matchingEntry?.instrument_token
}
export const getOrderDrawingsFromTrade = (trade, markOnChart, instrumentToken) => {
  const orderIdList = markOnChart.orders[trade.trade_id] ? markOnChart.orders[trade.trade_id] : []
  const tradeOrders = trade.allOrders
  const instrumentOrders = tradeOrders.filter(o => o.instrument_token === instrumentToken)
  const markedOrders = instrumentOrders.filter( o => Object.keys(orderIdList).includes(o.log_id) && orderIdList[o.log_id])
  //arrange by token
  let drawingList = {}
  for(let i = 0; i < markedOrders.length; i++) {
    const currentOrder = markedOrders[i]
    const currentOrderToken = currentOrder.instrument_token
    const currentDrawing = {
      "type": "ORDERS",
      "details": {
        "age": 1,
        "price": currentOrder.price
      }
    }
    if(drawingList[currentOrderToken]) {
      drawingList[currentOrderToken].push(currentDrawing)
    } else {
      drawingList[currentOrderToken] = [currentDrawing]
    }
  }
  return drawingList
}

const getDrawingListFromTrigger = (trigger) => {
  switch (trigger.type) {
    case 'EMULATED_CO': {
      return [
        {
          "type": "TRIGGERS",
          "details": {
            "age": 1,
            "price": trigger.triggerDetails.order.triggerPrice
          }
        },
        {
          "type": "TRIGGERS",
          "details": {
            "age": 1,
            "price": trigger.triggerDetails.sl.triggerPrice
          }
        }
      ]

    }
    case 'TRIGGER_ORDER': {
      return [
        {
          "type": "TRIGGERS",
          "details": {
            "age": 1,
            "price": trigger.triggerDetails.order.triggerPrice
          }
        }
      ]
    }

    case 'TRIGGER_ORDER2': {
      return [
        {
          "type": "TRIGGERS",
          "details": {
            "age": 1,
            "price": trigger.params.triggerPrice
          }
        }
      ]
    }

    case 'TRIGGER_SQUAREOFF': {
      return [
        {
          "type": "TRIGGERS",
          "details": {
            "age": 1,
            "price": trigger.params.triggerPrice
          }
        }
      ]
    }
    default:
      return []
  }

}

//TODO temporarily until all triggers are migrated to the new params format
const getInstrumentToBeMarkedFromTrigger = (trigger) => {
  let triggerInstrumentToBeMarked
  if(trigger.type === 'EMULATED_CO') triggerInstrumentToBeMarked = trigger.triggerDetails.instrument
  if(trigger.type === 'TRIGGER_SQUAREOFF') triggerInstrumentToBeMarked = trigger.params.instrument
  if(trigger.type === 'TRIGGER_ORDER2') triggerInstrumentToBeMarked = trigger.params.triggerInstrument
  return triggerInstrumentToBeMarked
}

export const getTriggerDrawingsFromTrade = (trade, markOnChart, instrumentToken) => {
  const triggerIdList = markOnChart.triggers[trade.trade_id] ? markOnChart.triggers[trade.trade_id] : []
  const tradeTriggers = trade.triggers
  let triggerInstrumentToBeMarked
  const instrumentTriggers = tradeTriggers.filter(o => {
    triggerInstrumentToBeMarked = getInstrumentToBeMarkedFromTrigger(o)
    return triggerInstrumentToBeMarked && triggerInstrumentToBeMarked.token === instrumentToken
  })
  const markedTriggers = instrumentTriggers.filter( o => Object.keys(triggerIdList).includes(o.trigger_id) && triggerIdList[o.trigger_id])
  //arrange by token
  let drawingList = {}
  for(let i = 0; i < markedTriggers.length; i++) {
    const currentTrigger = markedTriggers[i]
    const {token: currentTriggerToken} = getInstrumentToBeMarkedFromTrigger(currentTrigger)
    const currentDrawings = getDrawingListFromTrigger(currentTrigger)
    if(drawingList[currentTriggerToken]) {
      drawingList[currentTriggerToken] = [...drawingList[currentTriggerToken], ...currentDrawings]
    } else {
      drawingList[currentTriggerToken] = [...currentDrawings]
    }
  }
  return drawingList
}
export const computePositions = (orders) => {

  let positions = {}
  //initialize instruments
  orders.forEach((item) => {
    const tradingsymbol = item.tradingsymbol;
    const keys = Object.keys(positions);
    if (!keys.includes(tradingsymbol)) {
      positions[tradingsymbol] = {};
    }
  });

  const completedOrders = orders.filter(
    (order) => order.status === "COMPLETE"
  );
  //calculate position size of each instrument
  const keys = Object.keys(positions);
  keys.forEach((tradingsymbol) => {
    const instrumentCompletedOrders = completedOrders.filter(
      (order) => order.tradingsymbol === tradingsymbol
    );
    positions[tradingsymbol] = instrumentCompletedOrders.reduce(
      (accumulator, current) => {
        if (current.transaction_type === "BUY") {
          return accumulator + current.quantity;
        }
        return accumulator - current.quantity;
      },
      0
    );
  });
  return positions
}

export const computeM2M = async (orders) => {
  const instruments = window.MY_INSTRUMENTS
  let positions = {}
  let positionsM2M = {}
  //initialize instruments
  orders.forEach((item) => {
    const tradingsymbol = item.tradingsymbol;
    const keys = Object.keys(positions);
    if (!keys.includes(tradingsymbol)) {
      positions[tradingsymbol] = {};
      positionsM2M[tradingsymbol] = {};
    }
  });

  const completedOrders = orders.filter(
    (order) => order.status === "COMPLETE"
  );
  //calculate position size of each instrument
  const keys = Object.keys(positions);
  for (let k = 0; k < keys.length; k++) {
    const tradingsymbol = keys[k]
    const instrumentCompletedOrders = completedOrders.filter(
      (order) => order.tradingsymbol === tradingsymbol
    );
    const position = instrumentCompletedOrders.reduce(
      (accumulator, current) => {
        if (current.transaction_type === "BUY") {
          return accumulator + (current.quantity * 1);
        }
        return accumulator - current.quantity;
      },
      0
    );
    const m2m = instrumentCompletedOrders.reduce(
      (accumulator, current) => {
        if (current.transaction_type === "BUY") {
          return accumulator - (current.quantity * current.price);
        }
        return accumulator + (current.quantity * current.price);
      },
      0
    );
    positions[tradingsymbol] = m2m
    console.log('m2m: ', m2m)
    if(position > 0 || position < 0) {
      const tokenNumbersOfCompleted = completedOrders.map(o => o.instrument_token)
      const symbol = tradingsymbol
      const miniTradingSymbol = symbol.substr(symbol.indexOf(':') + 1)
      const exchange = symbol.substr(0, symbol.indexOf(':'))
      const getInstrument = instruments.find(i => i.tradingsymbol === miniTradingSymbol && i.exchange === exchange)
      let ltpInfo = await getLTP(tokenNumbersOfCompleted);
      console.log('ltpInfo: ', ltpInfo)
      ltpInfo = ltpInfo.data
      const currentLTP =  ltpInfo[getInstrument.instrument_token].last_price
      const openPositionAdjustment = position * currentLTP
      positions[tradingsymbol] = m2m + openPositionAdjustment
    }
  }
  return positions
}

export const getNiftyOptions = (price, bors) => {
  //nifty 15233
  let result;
  if (bors) {
    //CE DITM 15000 ITM 15100 ATM 15200 OTM 15300 DOTM 15400
    const ATM = price - (price % 100) + 100;
    result = {
      DITM: ATM - 200,
      ITM: ATM - 100,
      ATM,
      OTM: ATM + 100,
      DOTM: ATM + 200,
    };
  } else {
    //PE DITM 15500 ITM 15400 ATM 15300 OTM 15200 DOTM 15100
    const ATM = price - (price % 100);
    result = {
      DITM: ATM + 200,
      ITM: ATM + 100,
      ATM,
      OTM: ATM - 100,
      DOTM: ATM - 200,
    };
  }
  return result;
};
export const highlightText = (text, query) => {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens= [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

const escapeRegExpChars = (text) => {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const isLocal = () => {
  return process.env.NODE_ENV === 'development'
}

export const isProd = () => {
  return process.env.NODE_ENV === 'production'
}
