import useDimensions from "react-use-dimensions"
import Chart from "./Chart"
import React from "react"
import {Button, Icon} from "@blueprintjs/core"

const ChartWrapper = ({
                        chartData,
                        chartName,
                        interval,
                        drawings,
                        mainDispatch,
                        panEnabled,
                        zoomEnabled,
                        instrumentToken
                      }) => {
  const [ref, {width}] = useDimensions()
  return (
    <div className={'chart-container'} ref={ref}>
      <div className={'flex text-3xl text-center space-x-4 justify-center'}>
        <div className={'text-center text-3xl'}>{chartName} - {interval} ({instrumentToken})</div>
        <Button minimal icon={<Icon icon={"zoom-in"} color={`${zoomEnabled ? 'white' : 'gray'}`}/>}  onClick={() => mainDispatch({type: 'ZOOM_ENABLE', enable: !zoomEnabled})} />
        <Button minimal icon={<Icon icon={"hand"} color={`${panEnabled ? 'white' : 'gray'}`}/>}  onClick={() => mainDispatch({type: 'PAN_ENABLE', enable: !panEnabled})} />
      </div>
      {
        chartData ?
          <Chart
            data={chartData}
            instrumentName={chartName}
            interval={interval}
            width={width}
            drawings={drawings}
            mainDispatch={mainDispatch}
            zoomPanEnabl
            zoomEnabled={zoomEnabled}
            panEnabled={panEnabled}
          />
          : ''
      }
    </div>)
}

export default ChartWrapper
