import React from "react"
import {Link} from "react-router-dom"

const PositionsTable = ({positions, onControl}) => {
  const keys = Object.keys(positions)
  if (keys.length === 0) return <div style={{paddingLeft: '10px', marginTop: '20px'}}>No positions</div>
  return (
    <table className={"bp3-html-table bp3-small mt-6"}>
      <thead>
      <tr>
        <th>Instrument</th>
        <th>Position</th>
      </tr>
      </thead>
      <tbody>
      {
        keys.map(instrument => (
          <tr key={instrument}>
            <td><Link onClick={() => onControl({type: 'SWITCH_CHART', combinedSymbol: instrument})}>{instrument}</Link></td>
            <td>{positions[instrument]}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  )
}
export default PositionsTable
