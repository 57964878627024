import axios from "axios"
import config from "../config"

export const getTrades = () => {
  return axios.get(`${config.BASE_URL}/trades`)
}

export const getNonSignedOffTrades = () => {
  return axios.get(`${config.BASE_URL}/trades/nonSignedOff`)
}

export const postTrade = (trade) => {
  if(!trade.risk_profile?.risk) {
    trade.risk_profile = {risk: trade.risk_profile};
  }
    return axios.post(`${config.BASE_URL}/trades`, {...trade})
}

export const setTradeActive = (trade_id, setActive) => {
  return axios.post(`${config.BASE_URL}/trades/active`, {trade_id, setActive})
}

export const doTradeSignOff = (trade_id, doSignOff) => {
  return axios.post(`${config.BASE_URL}/trades/signoff`, {trade_id, doSignOff})
}

export const doTradeSquareOff = (trade_id) => {
  return axios.post(`${config.BASE_URL}/trades/squareoff`, {trade_id})
}

export const getTriggers = (trade_id) => {
  return axios.get(`${config.BASE_URL}/triggers/?trade_id=${trade_id}`)
}

export const addLog = (trade_id, log) => {
  return axios.post(`${config.BASE_URL}/trades/addLog`, {trade_id, log})
}

export const addInstrument = (trade_id, instrument_token) => {
  return axios.post(`${config.BASE_URL}/trades/instrument`, {trade_id, instrument_token})
}
