import {getLTP} from "../../../service/kc"
import {getNiftyOptions} from "../../../utils"
import {findToken} from "../components/ChartOptionsControls"

export const getTriggerCreateActionFromChartOrder = async (chartOrder, triggerDetailInfo, quantity, onControl) => {
  const chartOrderType = chartOrder.type
  let createTriggerAction = {algo_trade_id: '', algo_action_type: '', algo_action_info: ''}

  function getCreateTriggerActionForEmulatedCo(bors) {
    const {instrument: {exchange, token, symbol}, trade_id} = triggerDetailInfo
    const combinedSymbol = `${exchange}:${symbol}`
    const triggerPrice = chartOrder.stepState[0]
    const slTriggerPrice = chartOrder.stepState[1]
    let action = {}
    action.algo_action_info = {
      instrument: {token, symbol: combinedSymbol},
      goLong: bors,
      orderQuantity: multiplyByLotSize(token, quantity),
      triggerPrice,
      slTriggerPrice
    }
    action.algo_action_type = 'CREATE_EMULATED_CO_TRIGGER'
    action.algo_trade_id = trade_id
    return action
  }

  async function getCreateTriggerActionFromTriggerOrder2(bors, instrumentToken, limitPriceOffset) {
    const {
      instrument: {exchange: triggerExchange, symbol: triggerSymbol, token: triggerInstrumentToken},
      trade_id
    } = triggerDetailInfo
    let orderInstrument
    if(!instrumentToken) {
      //get NIFTY CE ITM LTP
      const ltpInfo = await getLTP([256265])
      const niftyLTP = ltpInfo.data[256265]["last_price"]
      //assume it is for Nifty and auto detect instruments
      if(bors) {
        const niftyCallOptions = getNiftyOptions(niftyLTP, bors)
        orderInstrument = findToken('NIFTY', niftyCallOptions.ITM, bors)
      } else {
        const niftyPutOptions = getNiftyOptions(niftyLTP, bors)
        orderInstrument = findToken('NIFTY', niftyPutOptions.ITM, bors)
      }
    } else {
      console.log('instrumentToken: ', instrumentToken)
      orderInstrument = findInstrumentFromToken(instrumentToken)
      console.log('orderInstrument: ', orderInstrument)
    }
    let orderPrice
    if(limitPriceOffset) {
      const ltpInfo = await getLTP([orderInstrument.instrument_token])
      const orderInstrumentLTP = ltpInfo.data[orderInstrument.instrument_token]["last_price"]
      orderPrice = parseInt(orderInstrumentLTP) + parseFloat(limitPriceOffset)
    }
    const triggerPrice = chartOrder.stepState[0]
    const orderSymbol = orderInstrument.tradingsymbol
    const orderExchange = orderInstrument.exchange
    const orderCombinedSymbol = `${orderExchange}:${orderSymbol}`
    const triggerCombinedSymbol = `${triggerExchange}:${triggerSymbol}`
    let action= {}
    //goLong should always be true, since we always want to BUY CE or PE
    action.algo_action_info = {
      orderInstrument: {token: orderInstrument.instrument_token, symbol: orderCombinedSymbol},
      triggerInstrument: {token: triggerInstrumentToken, symbol: triggerCombinedSymbol},
      goLong: true,
      orderQuantity: multiplyByLotSize(orderInstrument.instrument_token, quantity),
      triggerPrice: triggerPrice,
    }
    if(orderPrice) {
      action.algo_action_info.orderPrice = orderPrice
    }
    action.algo_action_type = 'CREATE_TRIGGER_ORDER2_TRIGGER'
    action.algo_trade_id = trade_id
    return action
  }

  switch (chartOrderType) {
    case 'BUY_MARKET_EMULATED_CO': {
      createTriggerAction = getCreateTriggerActionForEmulatedCo(true)
      break;
    }
    case 'SELL_MARKET_EMULATED_CO': {
      createTriggerAction = getCreateTriggerActionForEmulatedCo(false)
      break;
    }
    case 'CE_MARKET_TRIGGER_ORDER2': {
      createTriggerAction = await getCreateTriggerActionFromTriggerOrder2(true, chartOrder.instrumentToken, chartOrder.limitPriceOffset)
      break;
    }
    case 'PE_MARKET_TRIGGER_ORDER2': {
      createTriggerAction = await getCreateTriggerActionFromTriggerOrder2(false, chartOrder.instrumentToken, chartOrder.limitPriceOffset)
      break;
    }
    case 'TRIGGER_SQUAREOFF': {
      const {instrument: {exchange, token, symbol}, trade_id} = triggerDetailInfo
      const triggerPrice = chartOrder.stepState[0]
      const combinedSymbol = `${exchange}:${symbol}`
      createTriggerAction.algo_action_info = {
        instrument: {token, symbol: combinedSymbol},
        triggerPrice
      }
      createTriggerAction.algo_action_type = 'CREATE_TRIGGER_SQUAREOFF_TRIGGER'
      createTriggerAction.algo_trade_id = trade_id
      break;
    }

    default:
      console.log('unknown chart order type in createTriggerFromChartOrder')
  }
  onControl({type: 'CREATE_ALGO_ACTION', algoAction: {...createTriggerAction}})
}

export const findInstrumentFromToken = (token) => {
  const instruments = window.MY_INSTRUMENTS
  if(!instruments) throw new Error("instruments not found")
  const instrument = instruments.find(i => i.instrument_token === token)
  return instrument
}

export const multiplyByLotSize = (token, quantity) => {
  const instrument = findInstrumentFromToken(token)
  return instrument.lot_size * quantity
}
