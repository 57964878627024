import moment from "moment-timezone"

export const addPaddingCandles = (candles) => {
  if(candles.length > 1) {
    const nItems = candles.length
    const lastCandle = candles[nItems - 1]
    const secondLastCandle = candles[nItems - 2]
    const timeDiff = moment(lastCandle.date).diff(moment(secondLastCandle.date))
    const paddingCandle = {...lastCandle}
    paddingCandle.open = lastCandle.close
    paddingCandle.high = lastCandle.close
    paddingCandle.low = lastCandle.close
    const lastCandleDate = moment(lastCandle.date).tz('UTC')
    paddingCandle.date = lastCandleDate.add(timeDiff).format()
    candles.push(paddingCandle)
    candles.push({...paddingCandle, date: lastCandleDate.add(timeDiff*2).format})
    return candles
  }
}